import React from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { Heading1 } from "../components/Heading"
import Section from "../components/Section"

const NotFoundPage = () => {
  const intl = useIntl()

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: "page.notfound.title" })} />
      <Section narrow>
        <Heading1>
          <FormattedMessage id="notfound.title" />
        </Heading1>
        <FormattedMessage id="notfound.text" tagName="p" />
      </Section>
    </Layout>
  )
}

export default NotFoundPage
